module.exports = [
	{
        type: 'block',
		css: {
			top: 60,
			left: 15,
			width: 328,
			height: 264,
			backgroundColor:'rgba(252, 255, 250, 1)',
        },
	},
	{
        type: 'image',
        id: 'bg-img', //默认允许编辑
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-2-7/poster12-2.png',
		css: {
			top: 72,
			left: 16,
			width: 328,
			height: 244,
        },
	},
	{
		type: 'image',
		url:'https://doc.efreight.cn/weapp/zjhy/static/2021-2-7/poster12-1.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
	},
	{
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2020-11-20/avatar.jpg',
		css: {
			top: 23,
			left: 130,
			width: 34,
			height: 34,
			borderRadius: true,
		},
	},

    {
		type: 'image',
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-2-7/poster12-3.png',
		css: {
			top: 366,
			left: 180,
			width: 40,
            height: 20,
			originX:'center',
		},
    },
    {
		type: 'text',
		id: 'flightPlan',
		text: '-',
		css: {
			top: 457,
			left: 40,
			color:'#333333',
			fontSize: 18,
			fontWeight: 500,
		}
    },
    {
		type: 'text',
		id: 'min-txt',
		text: '￥',
		css: {
			top: 493,
			left: 40,
			color: '#B10F29',
			fontSize: 32,
		}
    },
    {
		type: 'text',
		id: 'min-price',
		text: '最低价格',
		css: {
			top: 480,
			left: 66,
			color: '#B10F29',
			fontSize: 48,
		}
    },
	{
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 452,
			left: 241,
			width: 79,
			height: 79,
            color:'#000',
            light:'#0000',
		}
	},
	{
		type: 'text',
		text: '扫码享受更多优惠',
		css: {
			top: 554,
			left: 180,
			color:'#FFFFFF',
			fontSize: 14,
			originX:'center',
		},
		editAble: true
	},
	{
		type: 'text',
		id: 'dep',
		text: '始发',
		css: {
			top: 366,
            left: 40,
			color:'#333',
			fontSize: 44,
            fontWeight: 500,
		}
    },
    {
		type: 'text',
		id: 'des',
		text: '目的',
		css: {
			top: 366,
            left: 224,
			color:'#333',
			fontSize: 44,
            fontWeight: 500,
		}
	},
	{
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 30,
			left: 175, 
			color:'#fff',
			fontWeight: 600,
            fontSize:16,
            shadow: '0px 2px 4px #7B7B7B',
		},
		editAble: true
	},
	{
		type: 'text',
		id: 'product-title',
		text: '商品名称',
		css: {
			top: 330,
			left: 40,
			color: '#333',
			fontSize: 24,
			fontWeight: 500,
		},
		editAble: true
    },
]
module.exports = [
	{
		type: 'image',
		id: 'bg-img', //默认允许编辑
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-1-13/product-bg4.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
	},
	
	{
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2020-11-20/avatar.jpg',
		css: {
			top: 30,
			left: 280,
			width: 50,
			height: 50,
			borderRadius: true,
		},
	},
	{
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 90,
			left: 298, 
			width: 40,
			color:'#010600',
			fontWeight: 600,
			fontSize:16,
			fontFamily: 'SourceHanSerifCN-SemiBold, SourceHanSerifCN',
			breakWords: true,
		},
		editAble: true
	},
	{
		type: 'text',
		id: 'port',
		text: '始发港-目的港',
		css: {
			top: 356,
			left: 25,
			color:'#E66700',
			fontSize: 44,
			fontWeight: 500,
		}
	},
    {
		type: 'text',
		id: 'product-title',
		text: '商品名称',
		css: {
			top: 414,
			left: 25,
			width: 200,
			color: '#020B00',
			fontSize: 20,
		},
		editAble: true
    },
    {
		type: 'text',
		id: 'flightPlan',
		text: '航司信息',
		css: {
			top: 444,
			left: 25,
			color:'#F1C634',
			fontSize: 18,
			backgroundColor:'#000',
		}
    },
    {
		type: 'text',
		id: 'min-txt',
		text: '￥',
		css: {
			top: 475,
			left: 25,
			color: '#020B00',
			fontSize: 16,
		}
    },
    {
		type: 'text',
		id: 'min-price',
		text: '最低价格',
		css: {
			top: 470,
			left: 38,
			color: '#020B00',
			fontSize: 22,
		}
    },
	{
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 553,
			left: 275,
			width: 76,
			height: 76,
			color:'#000',
		}
	},
	// {
	// 	type: 'text',
	// 	text: '— 优惠价格不定期更新 —',
	// 	css: {
	// 		top: 534,
	// 		left: 180,
	// 		color:'#F6AF16',
	// 		fontSize: 16,
	// 		originX:'center',
	// 	},
	// 	editAble: true
	// },
]
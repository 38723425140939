module.exports = [
	{
		type: 'image',
		id: 'bg-img', //默认允许编辑
		url:'https://doc.efreight.cn/weapp/zjhy/static/2021-1-15/pro-poster3.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
	},
	{
		type: 'image',
		url:'https://doc.efreight.cn/weapp/zjhy/static/2021-1-15/poster3-other.png',
		css: {
			top: 165,
			left: 0,
			width: 360,
			height: 450,
		},
	},
	{
		type: 'image',
		url:' https://doc.efreight.cn/weapp/zjhy/static/2021-1-15/poster-3-title.png',
		css: {
			top: 140,
			left: 180,
			width: 162,
			height: 31,
			originX:'center',
		},
	},
	{
		type: 'text',
		id: 'product-title',
		text: '商品名称',
		css: {
			top: 74,
			left: 180,
			width: 200,
			color:'#fff',
			fontSize: 38,
			fontWeight: 600,
			originX:'center',
			textAlign: 'center',
		},
		editAble: true
	},
	{
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2020-11-20/avatar.jpg',
		css: {
			top: 130,
			left: 82,
			width: 50,
			height: 50,
			borderRadius: true,
		},
	},
	{
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 205,
			left: 180,
			width: 200,
			color:'#fff',
			originX:'center',
			
		},
		editAble: true
	},
	{
		type: 'text',
		text: '航司',
		css: {
			top: 284,
			left: 68,
			color:'#666666',
			fontSize: 18,
		}
	},
	{
		type: 'text',
		text: '班次',
		css: {
			top: 284,
			left: 164,
			color:'#666666',
			fontSize: 18,
		}
	},
	{
		type: 'text',
		text: '价格',
		css: {
			top: 284,
			left: 259,
			color:'#666666',
			fontSize: 18,
		}
	},
	{
		type: 'text',
		id: 'airline',
		text: '航司信息',
		css: {
			top: 334,
			left: 72,
			color:'#1054A5',
			fontSize: 18,
			// originX:'center',
		}
	},
	{
		type: 'text',
		id: 'flightPlan',
		text: '班次',
		css: {
			top: 334,
			left: 180,
			color:'#1054A5',
			fontSize: 18,
			originX:'center',
		}
	},
	{
		type: 'text',
		id: 'min-price',
		text: '最低价格',
		css: {
			top: 334,
			left: 275,
			color:'#1054A5',
			fontSize: 18,
			fontWeight: 500,
			originX:'center',
		}
	},
	{
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 464,
			left: 126,
			width: 110,
			height: 110,
			color:'#000',
			light:'#0000',
		}
	},
	{
		type: 'text',
		text: '— 优惠价格不定期更新 —',
		css: {
			top: 576,
			left: 180,
			color:'#F6AF16',
			fontSize: 16,
			originX:'center',
		},
		editAble: true
	},
]
module.exports = [
	{
		type: 'image',
		id: 'bg-img', //默认允许编辑
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-1-25/poster9-1.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
    },
    {
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 362,
			left: 133,
			width: 93,
			height: 93,
            color:'#000',
		}
	},
	{
		type: 'text',
		id: 'port',
		text: '始发港-目的港',
		css: {
			top: 220,
			left: 180,
			color:'#CF000D',
			fontSize: 40,
			originX:'center',
            fontWeight: 'bold',
		}
    },
    {
		type: 'text',
		text: '始发直飞',
		css: {
			top: 135,
			left: 64,
			width: 200,
			fontWeight: 500,
			color:'#000',
            fontSize: 24,
		},
		editAble: true
    },
    {
        type: 'line',
		css: {
            top: 230,
            left: 124,
			width: 220,
			height: 0.6,
			backgroundColor:'#666666',
        },
        editAble: true
    },
    {
		type: 'text',
		text: '目',
		css: {
			top: 112,
			left: 165,
			fontWeight: 500,
			color:'#000',
            fontSize: 66,
            fontFamily:'SourceHanSansCN-Medium, SourceHanSansCN',
		},
		editAble: true
    },
    {
		type: 'text',
		text: '的',
		css: {
			top: 133,
			left: 226,
			fontWeight: 500,
			color:'#000',
            fontSize: 66,
            fontFamily:'SourceHanSansCN-Medium, SourceHanSansCN',
		},
		editAble: true
    },
    {
        type: 'text',
		text: '航班',
		css: {
			top: 289,
			left: 54,
			color:'#333',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
        type: 'text',
		text: '班次',
		css: {
			top: 289,
			left: 196,
			color:'#333',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
        type: 'text',
        text: 'ETD',
		css: {
			top: 322,
			left: 54,
			color:'#333',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
        type: 'text',
        text: 'ETA',
		css: {
			top: 322,
			left: 196,
			color:'#333',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
        type: 'text',
        id: 'flightNo',
        text: '-',
		css: {
			top: 289,
			left: 98,
			color:'#333',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
        type: 'text',
        id: 'flightPlan',
        text: '-',
		css: {
			top: 289,
			left: 240,
			color:'#333',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
        type: 'text',
        id: 'ETD',
        text: '-',
		css: {
			top: 322,
			left: 98,
			color:'#333',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
        type: 'text',
        id: 'ETA',
        text: '-',
		css: {
			top: 322,
			left: 240,
			color:'#333',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2020-11-20/avatar.jpg',
		css: {
			top: 20,
			left: 17,
			width: 36,
			height: 36,
			borderRadius: true,
		},
    },
    {
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 28,
			left: 64,
			width: 200,
			color:'#3C5A7C',
			fontSize:16,
			fontWeight: 600,
			
		},
		editAble: true
	},
	
]
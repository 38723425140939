module.exports = [
	{
		type: 'image',
		id: 'bg-img', //默认允许编辑
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-1-15/poster5.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
	},
	{
		type: 'image',
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-1-15/poster5-1.png',
		css: {
			top: 276,
			left: 180,
			width: 316,
			height: 27,
			originX:'center',
		},
	},
	{
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2020-11-20/avatar.jpg',
		css: {
			top: 27,
			left: 27,
			width: 40,
			height: 40,
			borderRadius: true,
		},
    },
    {
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 37,
			left: 76,
			width: 200,
			color:'#fff',
			fontSize:16,
			fontWeight: 500,
            shadow: '0px 2px 4px rgba(213, 213, 213, 0.5)',
			
		},
		editAble: true
	},
    {
		type: 'text',
		id: 'product-title',
		text: '商品名称',
		css: {
			top: 120,
			left: 180,
			width: 200,
			fontWeight: 'bold',
			color:'#144A77',
			fontSize: 36,
            originX:'center',
            stroke:'#fff',
		},
		editAble: true
	},
	{
		type: 'text',
		id: 'port',
		text: '始发港-目的港',
		css: {
			top: 169,
			left: 180,
			color:'#F6DE9F',
			fontSize: 48,
			originX:'center',
            fontWeight: 500,
            shadow: '0px 1px 3px rgba(29, 94, 159, 0.8)',
		}
	},
	{
        type: 'text',
		text: '航班',
		css: {
			top: 250,
			left: 47,
			color:'#193F49',
            fontSize: 14,
		}
    },
    {
        type: 'text',
		text: '机型',
		css: {
			top: 250,
			left: 130,
			color:'#193F49',
            fontSize: 14,
		}
    },
    {
        type: 'text',
        text: '班次',
		css: {
			top: 250,
			left: 215,
			color:'#193F49',
            fontSize: 14,
		}
    },
    {
        type: 'text',
		text: '价格',
		css: {
			top: 250,
			left: 299,
			color:'#193F49',
            fontSize: 14,
		}
	},
	{
        type: 'text',
        id: 'flightNo',
		text: '-',
		css: {
			top: 281,
			left: 60,
			color:'#fff',
            fontSize: 16,
            originX:'center',
		}
    },
    {
        type: 'text',
        id: 'flightType',
		text: '-',
		css: {
			top: 281,
			left: 144,
			color:'#fff',
            fontSize: 16,
            originX:'center',
		}
    },
    {
        type: 'text',
        id: 'flightPlan',
        text: '-',
		css: {
			top: 281,
			left: 228,
			color:'#fff',
            fontSize: 16,
            originX:'center',
		}
    },
    {
        type: 'text',
        id: 'min-price',
		text: '价格',
		css: {
			top: 281,
			left: 312,
			color:'#fff',
            fontSize: 16,
            originX:'center',
		}
	},
	{
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 525,
			left: 240,
			width: 96,
			height: 96,
			color:'#000',
		}
	},
]
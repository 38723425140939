module.exports = [
	{
		type: 'image',
		id: 'bg-img', //默认允许编辑
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-2-1/poster10-1.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
    },
    {
		type: 'image',
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-2-1/poster10-2.png',
		css: {
			top: 24,
			left: 6,
			width: 346,
			height: 604,
		},
    },
    {
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 482,
			left: 133,
			width: 93,
			height: 93,
            color:'#000',
		}
	},
	{
		type: 'text',
		id: 'port',
		text: '始发港-目的港',
		css: {
			top: 198,
			left: 180,
			color:'#CF000D',
			fontSize: 24,
			originX:'center',
            fontWeight: 'bold',
		}
    },
    {
		type: 'text',
		id: 'product-title',
		text: '商品名称',
		css: {
			top: 248,
			left: 180,
			fontWeight: 800,
			color:'#C82E2D',
			fontSize: 33,
            originX:'center',
		},
		editAble: true
    },
    {
        type: 'block',
		css: {
			top:306,
            left: 40,
			width: 277,
            height: 49,
            rx: 6,
            ry: 6,
            backgroundColor:'rgba(246, 191, 111, 1)',
        },
        radius: true,
    },
    {
        type: 'text',
        id:'circle',
		text: '',
		css: {
			top: 314,
			// width: 36,
			// height: 33,
		},
    },
    {
		type: 'text',
		text: '日',
		css: {
			top: 321,
			left: 59,
			fontWeight: 500,
			color:'#333333',
            fontSize: 18,
		},
    },
    {
		type: 'text',
		text: '一',
		css: {
			top: 321,
			left: 92,
			fontWeight: 500,
			color:'#333333',
            fontSize: 18,
		},
    },
    {
		type: 'text',
		text: '二',
		css: {
			top: 321,
			left: 128,
			fontWeight: 500,
			color:'#333333',
            fontSize: 18,
		},
    },
    {
		type: 'text',
		text: '三',
		css: {
			top: 321,
			left: 168,
			fontWeight: 500,
			color:'#333333',
            fontSize: 18,
		},
    },
    {
		type: 'text',
		text: '四',
		css: {
			top: 321,
			left: 202,
			fontWeight: 500,
			color:'#333333',
            fontSize: 18,
		},
    },
    {
		type: 'text',
		text: '五',
		css: {
			top: 321,
			left: 238,
			fontWeight: 500,
			color:'#333333',
            fontSize: 18,
		},
    },
    {
		type: 'text',
		text: '六',
		css: {
			top: 321,
			left: 280,
			fontWeight: 500,
			color:'#333333',
            fontSize: 18,
		},
    },
    {
        type: 'text',
		text: '航班',
		css: {
			top: 381,
			left: 52,
			color:'#333',
            fontSize: 18,
            fontWeight: 400,
		}
    },
    {
        type: 'text',
        text: 'ETD',
		css: {
			top: 381,
			left: 198,
			color:'#333',
            fontSize: 18,
            fontWeight: 400,
		}
    },
    {
        type: 'text',
		text: '机型',
		css: {
			top: 416,
			left: 52,
			color:'#333',
            fontSize: 18,
            fontWeight: 400,
		}
    },
    
    {
        type: 'text',
        text: 'ETA',
		css: {
			top: 416,
			left: 198,
			color:'#333',
            fontSize: 18,
            fontWeight: 400,
		}
    },
    {
        type: 'text',
        id: 'flightNo',
        text: '-',
		css: {
			top: 381,
			left: 96,
			color:'#333',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
        type: 'text',
        id: 'flightType',
        text: '-',
		css: {
			top: 416,
			left: 96,
			color:'#333',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
        type: 'text',
        id: 'ETD',
        text: '-',
		css: {
			top: 381,
			left: 242,
			color:'#333',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
        type: 'text',
        id: 'ETA',
        text: '-',
		css: {
			top: 416,
			left: 242,
			color:'#333',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2020-11-20/avatar.jpg',
		css: {
			top: 24,
			left: 130,
			width: 36,
			height: 36,
			borderRadius: true,
		},
    },
    {
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 32,
			left: 175,
			width: 200,
			color:'#FFFFFF',
			fontSize:16,
			fontWeight: 600,
			
		},
		editAble: true
	},
	
]
module.exports = [
	
	{
        type: 'image',
        id: 'bg-img', //默认允许编辑
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-1-21/poster7-4.png',
		css: {
			top: 16,
			left: 16,
			width: 328,
			height: 376,
        },
	},
	{
		type: 'image',
		url:'https://doc.efreight.cn/weapp/zjhy/static/2021-1-21/poster7-1.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
    },
    {
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2020-11-20/avatar.jpg',
		css: {
			top: 344,
			left: 32,
			width: 34,
			height: 34,
			borderRadius: true,
		},
	},
	{
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 352,
			left: 74, 
			color:'#fff',
			fontWeight: 600,
            fontSize:16,
            shadow: '0px 2px 4px #7B7B7B',
		},
		editAble: true
    },
    {
		type: 'image',
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-1-21/poster7-2.png',
		css: {
			top: 102,
			left: 180,
			width: 189,
            height: 46,
			originX:'center',
		},
    },
    {
        type: 'block',
		css: {
			top: 390,
            left: 15.5,
			width: 328,
			height: 233,
            backgroundColor:'white',
        },
    },
    {
		type: 'image',
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-1-21/poster7-3.png',
		css: {
			top: 455,
			left: 234,
			width: 103,
            height: 36,
		},
    },
    {
		type: 'line',
		css: {
			top: 567,
			left: 90,
			width: 390,
			height: 0.6,
			backgroundColor:'#DB820E',
		},
		dashed:true,
    },
    {
        type: 'text',
		text: '航班',
		css: {
			top: 522,
			left: 32,
			color:'#1B2B72',
            fontSize: 14,
            fontWeight: 500,
            fontFamily: 'SourceHanSansCN-Medium, SourceHanSansCN',
		}
    },
    {
        type: 'text',
		text: '机型',
		css: {
			top: 554,
			left: 32,
			color:'#1B2B72',
            fontSize: 14,
            fontWeight: 500,
            fontFamily: 'SourceHanSansCN-Medium, SourceHanSansCN',
		}
    },
    {
        type: 'text',
        text: '班期',
		css: {
			top: 585,
			left: 32,
			color:'#1B2B72',
            fontSize: 14,
            fontWeight: 500,
            fontFamily: 'SourceHanSansCN-Medium, SourceHanSansCN',
		}
    },
	{
        type: 'text',
        id: 'flightNo',
		text: '-',
		css: {
			top: 522,
			left: 81,
			color:'#333',
            fontSize: 16,
            fontWeight: 500,
            fontFamily: 'SourceHanSansCN-Medium, SourceHanSansCN',
		}
    },
    {
        type: 'text',
        id: 'flightType',
		text: '-',
		css: {
			top: 554,
			left: 81,
			color:'#333',
            fontSize: 16,
            fontWeight: 500,
            fontFamily: 'SourceHanSansCN-Medium, SourceHanSansCN',
		}
    },
    {
        type: 'text',
        id: 'flightPlan',
        text: '-',
		css: {
			top: 585,
			left: 81,
			color:'#333',
            fontSize: 16,
            fontWeight: 500,
            fontFamily: 'SourceHanSansCN-Medium, SourceHanSansCN',
		}
    },
	{
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 512,
			left: 241,
			width: 93,
			height: 93,
            color:'#000',
            light:'#0000',
		}
	},
	{
		type: 'text',
		id: 'product-title',
		text: '商品名称',
		css: {
			top: 410,
			left: 28,
			color: '#1B2B72',
			fontSize: 32,
            fontWeight: 800,
            fontFamily: 'PingFangSC-Heavy, PingFang SC',
		},
		editAble: true
    },
    {
		type: 'text',
		id: 'port',
		text: '始发港-目的港',
		css: {
			top: 455,
			left: 28,
			color:'#DB820E',
			fontSize: 38,
            fontWeight: 800,
            fontFamily: 'PingFangSC-Heavy, PingFang SC',
		}
    },
    {
		type: 'text',
		id: 'min-price',
		text: '价格',
		css: {
			top: 461,
			left: 283,
			color: '#E7D684',
            fontSize: 28,
            originX:'center',
		}
    },
]
module.exports = [
    {
        type: 'image',
        id: 'bg-img', //默认允许编辑
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-2/pro-poster14.jpg',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
		editAble: false
    },
	{
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 526,
			left: 246,
			width: 93,
			height: 93,
            color:'#000',
		}
    },
	{
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 25,
			left: 60,
			width: 200,
			color:'#ffffff',
			fontSize:16,
            fontWeight: 500,
			
		},
		editAble: true
    },
	{
		type: 'text',
		id: 'product-title',
		text: '商品名称',
		css: {
			top: 110,
			left: 180,
			color:'#fff',
			fontSize: 29,
            originX:'center',
		},
		editAble: true
    },
	{
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2020-11-20/avatar.jpg',
		css: {
			top: 17,
			left: 20,
			width: 36,
			height: 36,
			borderRadius: true,
		},
    },
    // {
    //     type: 'circle',
	// 	css: {
	// 		top: 13,
	// 		left: 14,
	// 		width: 38,
    //         height: 38,
    //         color:'#175AC9'
    //     },
    // },
	{
        type: 'text',
		text: '航班',
		css: {
			top: 381,
			left: 52,
			color:'#fff',
            fontSize: 18,
            fontWeight: 400,
		}
    },
    {
        type: 'text',
        text: 'ETD',
		css: {
			top: 381,
			left: 198,
			color:'#fff',
            fontSize: 18,
            fontWeight: 400,
		}
    },
    {
        type: 'text',
		text: '机型',
		css: {
			top: 416,
			left: 52,
			color:'#fff',
            fontSize: 18,
            fontWeight: 400,
		}
    },
    
    {
        type: 'text',
        text: 'ETA',
		css: {
			top: 416,
			left: 198,
			color:'#fff',
            fontSize: 18,
            fontWeight: 400,
		}
    },
    {
        type: 'text',
        id: 'flightNo',
        text: '-',
		css: {
			top: 381,
			left: 96,
			color:'#fff',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
        type: 'text',
        id: 'flightType',
        text: '-',
		css: {
			top: 416,
			left: 96,
			color:'#fff',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
        type: 'text',
        id: 'ETD',
        text: '-',
		css: {
			top: 381,
			left: 242,
			color:'#fff',
            fontSize: 18,
            fontWeight: 500,
		}
    },
    {
        type: 'text',
        id: 'ETA',
        text: '-',
		css: {
			top: 416,
			left: 242,
			color:'#fff',
            fontSize: 18,
            fontWeight: 500,
		}
    },
	{
        type: 'block',
		css: {
			top: 355,
			left: 30,
			width: 300,
			height: 100,
            backgroundColor:'rgba(0, 0, 0, 0.2)',
        },
    },
	{
		type: 'text',
		id: 'port',
		text: '始发港-目的港',
		css: {
			top: 520,
			left: 30,
			color:'#DB820E',
			fontSize: 30,
            fontWeight: 800,
            fontFamily: 'PingFangSC-Heavy, PingFang SC',
		}
    },
	{
		type: 'text',
		id: 'min-price',
		text: '价格',
		css: {
			top: 570,
			left: 30,
			color: '#E7D684',
            fontSize: 28,
			// backgroundColor: '#F7B500',
			// width: 200,
			// padding: 5,
            // originX:'center',
		}
    },
	{
		type: 'block',
		css: {
			top: 228,
			left: 230,
			width: 100,
			height: 100,
			rx: 50,
			ry: 50,
			backgroundColor: '#fff'
		},
		radius: true,
	},
	{
		type: 'image',
		css: {
			top: 231,
			left: 233,
			width: 96,
			height: 96,
			borderRadius: true
		},
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-2/pro-poster14-3.jpg'
	},

	{
		type: 'block',
		css: {
			top: 215,
			left: 120,
			width: 126,
			height: 126,
			rx: 63,
			ry: 63,
			backgroundColor: '#fff'
		},
		radius: true,
	},
	{
		type: 'image',
		css: {
			top: 218,
			left: 123,
			width: 121,
			height: 121,
			borderRadius: true
		},
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-2/pro-poster14-2.jpg',
		// editAble: true
	},

	{
		type: 'block',
		css: {
			top: 228,
			left: 30,
			width: 100,
			height: 100,
			rx: 50,
			ry: 50,
			backgroundColor: '#fff'
		},
		radius: true,
	},
	{
		type: 'image',
		css: {
			top: 231,
			left: 33,
			width: 96,
			height: 96,
			borderRadius: true
		},
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-2/pro-poster14-1.jpg'
	},

]
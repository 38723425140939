module.exports = [
	{
		type: 'image',
		id: 'bg-img', //默认允许编辑
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-1-25/poster8-2.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
	},
	{
		type: 'image',
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-1-25/poster8-1.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
	},
	{
		type: 'text',
		id: 'port',
		text: '始发港-目的港',
		css: {
			top: 151,
			left: 180,
			color:'#0950AC',
			fontSize: 40,
			originX:'center',
            fontWeight: 'bold',
            shadow: '0px 1px 3px rgba(29, 94, 159, 0.8)',
		}
    },
    {
		type: 'text',
		id: 'product-title',
		text: '商品名称',
		css: {
			top: 206,
			left: 180,
			width: 200,
			fontWeight: 500,
			color:'#0950AC',
			fontSize: 18,
            originX:'center',
		},
		editAble: true
    },
    {
        type: 'text',
        id: 'flightPlan',
        text: '-',
		css: {
			top: 244,
			left: 180,
			color:'#0950AC',
            fontSize: 18,
            originX:'center',
		}
    },
    {
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2020-11-20/avatar.jpg',
		css: {
			top: 27,
			left: 27,
			width: 36,
			height: 36,
			borderRadius: true,
		},
    },
    {
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 35,
			left: 74,
			width: 200,
			color:'#fff',
			fontSize:16,
			fontWeight: 500,
            shadow: '0px 2px 4px #7B7B7B',
			
		},
		editAble: true
	},
	{
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 500,
			left: 218,
			width: 93,
			height: 93,
			color:'#000',
		}
	},
]
module.exports = [
	{
		type: 'image',
		id: 'bg-img', //默认允许编辑
		url:'https://doc.efreight.cn/weapp/zjhy/static/2021-1-15/pro-poster2.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
	},
	{
		type: 'image',
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-1-15/pro-poster-bg.png',
		css: {
			top: 101,
			left: 43,
			width: 275,
			height: 212,
			// originX:'center',
		},
	},
	{
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2020-11-20/avatar.jpg',
		css: {
			top: 64,
			left: 154,
			width: 50,
			height: 50,
			borderRadius: true,
		},
	},
	{
		type: 'text',
		id: 'port',
		text: '始发港-目的港',
		css: {
			top: 130,
			left: 180,
			color:'#F7A466',
			fontSize: 42,
			originX:'center',
			fontWeight: 500,
		}
	},
	{
		type: 'text',
		id: 'product-title',
		text: '商品名称',
		css: {
			top:190,
			left: 180,
			width: 200,
			color:'RGBA(255, 255, 255, 0.7)',
			fontSize: 18,
			originX:'center',
		},
		editAble: true
	},
	{
		type: 'text',
		id: 'flightPlan',
		text: '班次',
		css: {
			top: 226,
			left: 180,
			color:'#fff',
			fontSize: 16,
			fontWeight:500,
			originX:'center',
		}
	},
	{
		type: 'text',
		id: 'min-txt',
		text: '只要',
		css: {
			top: 274,
			left: 116,
			color:'#FFFFFF',
			fontSize: 18,
		}
	},
	{
		type: 'text',
		id: 'min-txt',
		text: '￥',
		css: {
			top: 274,
			left: 158,
			color:'#F7A466',
			fontSize:20,
			fontWeight: 500,
		}
	},
	{
		type: 'text',
		id: 'min-price',
		text: '最低价格',
		css: {
			top: 266,
			left: 176,
			color:'#F7A466',
			fontSize: 30,
			fontWeight: 500,
		}
	},
	{
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 482,
			left: 232,
			width: 96,
			height: 96,
			color:'#000',
		}
	},
	{
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 590,
			left: 278,
			width: 200,
			color:'#fff',
			fontSize:18,
			fontWeight: 500,
			originX:'center',
			
		},
		editAble: true
	},
]
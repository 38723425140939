import * as POSTER1 from './1.js';
import * as POSTER2 from './2.js';
import * as POSTER3 from './3.js';
import * as POSTER4 from './4.js';
import * as POSTER5 from './5.js';
import * as POSTER6 from './6.js';
import * as POSTER7 from './7.js';
import * as POSTER8 from './8.js';
import * as POSTER9 from './9.js';
import * as POSTER10 from './10.js';
import * as POSTER11 from './11.js';
import * as POSTER12 from './12.js';
import * as POSTER13 from './13.js';
import * as POSTER14 from './14.js';
const array = [
	[],
	POSTER1,
	POSTER2,
	POSTER3,
	POSTER4,
	POSTER5,
	POSTER6,
	POSTER7,
	POSTER8,
	POSTER9,
	POSTER10,
	POSTER11,
	POSTER12,
	POSTER13,
	POSTER14,
]
export default array
module.exports = [
	{
		type: 'image',
		id: 'bg-img', //默认允许编辑
		url:'https://doc.efreight.cn/weapp/zjhy/static/2021-1-19/poster1-3.png',
		css: {
			top: 111,
			left: 180,
			width: 243,
			height: 154,
			originX:'center',
		},
	},
	{
		type: 'image',
		url:'https://doc.efreight.cn/weapp/zjhy/static/2021-1-20/poster1-0120.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
	},
	{
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2020-11-20/avatar.jpg',
		css: {
			top: 18,
			left: 26,
			width: 50,
			height: 50,
			borderRadius: true,
		},
	},
	{
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 20,
			left: 90,
			width: 200,
			color:'#fff',
			fontSize: 18,
			
		},
		editAble: true
	},
	{
		type: 'text',
		text: '扫码进店享优惠',
		css: {
			top: 50,
			left: 90,
			color:'#fff',
			fontSize: 14,
		}
	},
	{
		type: 'text',
		id: 'product-title',
		text: '',
		css: {
			left: 34, 
			top: 147 ,
			fontSize: 14,
			fontWeight: 400,
			color:'#807768',
			breakWords: true,
		},
		editAble: true
	},
	{
		type: 'text',
		id: 'product-title',
		text: '',
		css: {
			left: 311, 
			top: 450,
			fontSize: 14,
			fontWeight: 400,
			color:'#807768',
			breakWords: true,
		},
		productTitle:'title2',
		editAble: true
	},
	{
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 489,
			left: 141,
			width: 78,
			height: 78,
			color:'#000',
			light:'#0000',
		}
	},
	{
		type: 'text',
		id: 'port',
		text: '始发港-目的港',
		css: {
			top: 325,
			left: 180,
			color:'#A72725',
			fontSize: 28,
			originX:'center',
			fontWeight: 500,
		}
	},
	{
		type: 'text',
		id: 'flightPlan',
		text: '班次',
		css: {
			top: 360,
			left: 180,
			color:'#333',
			fontSize: 16,
			originX:'center',
		}
	},
	{
		type: 'text',
		id: 'min-txt',
		text: '仅需',
		css: {
			top: 396,
			left: 125,
			color:'#333',
			fontSize: 16,
		}
	},
	{
		type: 'text',
		id: 'min-txt',
		text: '￥',
		css: {
			top: 398,
			left: 166,
			color:'#B10F29',
			fontSize:16,
			fontWeight: 500,
		}
	},
	{
		type: 'text',
		id: 'min-price',
		text: '最低价格',
		css: {
			top: 390,
			left: 181,
			color:'#B10F29',
			fontSize: 26,
			fontWeight: 500,
		}
	},
	
]
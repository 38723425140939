module.exports = [
    {
        type: 'image',
        id: 'bg-img', //默认允许编辑
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-3-26/pro-poster13-1.png',
		css: {
			top: 301,
			left: 0,
			width: 360,
			height: 339,
		},
    },
    {
		type: 'image',
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-3-26/pro-poster13.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 389,
		},
    },
    {
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 526,
			left: 246,
			width: 93,
			height: 93,
            color:'#000',
		}
    },
    {
		type: 'image',
		url: ' https://doc.efreight.cn/weapp/zjhy/static/2021-3-26/pro-poster13-2.png',
		css: {
			top: 210.6,
			left: 132,
			width: 77,
            height: 16,
		},
    },
    {
        type: 'block',
		css: {
			top:285,
            left: 40,
			width: 277,
            height: 49,
            rx: 6,
            ry: 6,
            backgroundColor:'rgba(220, 233, 255, 1)',
        },
        radius: true,
    },
    {
		type: 'text',
		id: 'product-title',
		text: '商品名称',
		css: {
			top: 150,
			left: 180,
			color:'#175AC9',
			fontSize: 29,
            originX:'center',
		},
		editAble: true
    },
	{
		type: 'text',
		id: 'dep',
		text: 'CAN',
		css: {
			top: 205,
			left: 49,
			color:'#333',
			fontSize: 29,
            fontWeight: '800',
		}
    },
    {
		type: 'text',
		id: 'des',
		text: 'AMS',
		css: {
			top: 205,
			left: 248,
			color:'#333',
			fontSize: 29,
            fontWeight: '800',
		}
    },
    {
        type: 'text',
        id: 'flightNo',
		text: '',
		css: {
			top: 211,
			left: 170,
			color:'#175AC9',
            fontSize: 14,
            originX:'center',
		},
    },
    {
        type: 'text',
        id: 'ETD',
        text: '',
		css: {
			top: 233,
			left: 79,
			color:'#666',
            fontSize: 16,
            originX:'center',
		}
    },
    {
        type: 'text',
        id: 'ETA',
        text: '',
		css: {
			top: 233,
			left: 290,
			color:'#666',
            fontSize: 16,
            originX:'center',
		}
    },
    
    {
        type: 'text',
        id:'circle',
		text: '',
		css: {
			top: 314,
		},
    },
    {
		type: 'text',
		text: '日',
		css: {
			top: 298,
			left: 59,
			fontWeight: 500,
			color:'#333333',
            fontSize: 18,
		},
    },
    {
		type: 'text',
		text: '一',
		css: {
			top: 298,
			left: 92,
			fontWeight: 500,
			color:'#333333',
            fontSize: 18,
		},
    },
    {
		type: 'text',
		text: '二',
		css: {
			top: 298,
			left: 128,
			fontWeight: 500,
			color:'#333333',
            fontSize: 18,
		},
    },
    {
		type: 'text',
		text: '三',
		css: {
			top: 298,
			left: 168,
			fontWeight: 500,
			color:'#333333',
            fontSize: 18,
		},
    },
    {
		type: 'text',
		text: '四',
		css: {
			top: 298,
			left: 202,
			fontWeight: 500,
			color:'#333333',
            fontSize: 18,
		},
    },
    {
		type: 'text',
		text: '五',
		css: {
			top: 298,
			left: 238,
			fontWeight: 500,
			color:'#333333',
            fontSize: 18,
		},
    },
    {
		type: 'text',
		text: '六',
		css: {
			top: 298,
			left: 280,
			fontWeight: 500,
			color:'#333333',
            fontSize: 18,
		},
    },
    {
        type: 'block',
		css: {
			top: 340,
			left: 112,
			width: 82,
			height: 24,
            backgroundColor:'rgba(224, 32, 32, 1)',
            rx:12,
            ry:12,
        },
        radius:true,
    },
    {
        type: 'text',
        id:'time',
		text: '-',
		css: {
			top: 340,
            left: 152,
            fontFamily: 'Facon',
			color:'#fff',
            fontSize: 22,
            originX:'center',
		},
    },
    {
		type: 'text',
		text: '启航',
		css: {
			top: 340,
			left: 200,
			fontWeight: 'bold',
			color:'#CF000D',
            fontSize: 24,
		},
    },
    // {
    //     type: 'block',
    //     id:'title-box',
	// 	css: {
	// 		top: 22,
	// 		left: 40,
	// 		width: 104,
	// 		height: 24,
    //         backgroundColor:'rgba(23, 90, 201, 1)',
    //         rx:12,
    //         ry:12,
    //     },
    //     radius:true,
    // },
    
    {
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 25,
			left: 60,
			width: 200,
			color:'#3C5A7C',
			fontSize:16,
            fontWeight: 500,
			
		},
		editAble: true
    },
    {
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2020-11-20/avatar.jpg',
		css: {
			top: 17,
			left: 20,
			width: 36,
			height: 36,
			borderRadius: true,
		},
    },
    // {
    //     type: 'circle',
	// 	css: {
	// 		top: 13,
	// 		left: 14,
	// 		width: 28,
    //         height: 28,
    //         color:'#175AC9'
    //     },
    // },
	
]